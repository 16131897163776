<template>
  <div
    v-if="isVisible"
    class="cookie-hint"
  >
    <div class="grid">
      <div class="grid__item cookie-hint__content">
        <p class="cookie-hint__description">
          Diese {{ wording }} verwendet Cookies, um Ihnen den bestmöglichen Service zu
          gewährleisten. Durch die Nutzung der {{ wording }} akzeptieren Sie die Verwendung von
          Cookies, weitere Informationen zur Datenschutzerklärung finden Sie
          <a
            :target="target"
            class="link link--secondary"
            href="http://www.telekom.de/start/datenschutz"
            >hier</a
          >
          und für Produkte
          <a
            :target="target"
            class="link link--secondary"
            href="http://www.telekom.de/datenschutzhinweise"
            >hier</a
          >.
        </p>
        <button
          class="btn btn--close cookie-hint__button"
          type="button"
          @click="confirm"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const STORAGE_KEY = 'cookieHintAccepted';

export default {
  name: 'CookieHint',
  props: {
    isApp: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '_blank',
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    wording() {
      return this.isApp ? 'App' : 'Webseite';
    },
  },
  mounted() {
    // The local storage access has been moved here so the hint will never be
    // displayed when rendered on the server side.
    this.isVisible = !localStorage.getItem(STORAGE_KEY);
  },
  methods: {
    confirm() {
      localStorage.setItem(STORAGE_KEY, true);
      this.isVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'assets/base';

.cookie-hint {
  background-color: color(grey, 12000);
  color: color(white);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 12px;

  .link {
    text-decoration: underline;
  }

  &__content {
    display: flex;
  }

  &__description {
    @include font-size(15px, 20px);
    margin: 0;
    padding: 0;
  }

  &__button {
    width: 80px;
    margin-left: 12px;
  }
}
</style>
