<template>
  <transition
    enter-active-class="flyout--transition"
    enter-class="flyout--is-closed"
    enter-to-class="flyout--is-opened"
    leave-active-class="flyout--transition"
    leave-class="flyout--is-opened"
    leave-to-class="flyout--is-closed"
    name="flyout"
    @after-enter="onOpened"
    @after-leave="onClosed"
  >
    <div
      v-if="isOpened"
      v-click-away="dismiss"
      :class="containerClass"
      class="flyout grid__item grid__item--12"
    >
      <div
        v-if="closable"
        class="flyout__close-container grid"
      >
        <button
          ref="closeButton"
          class="btn btn--transparent btn--borderless flyout__close-button"
          title="Schließen"
          type="button"
          @click="close"
          @keyup.esc="close"
        >
          <SvgIcon
            :image="closeIcon"
            class="flyout__icon-close"
          />
        </button>
      </div>

      <div class="flyout__content grid grid--center">
        <div class="grid__item grid__item--12">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { mixin as Clickaway } from 'vue3-click-away';
import closeIcon from '@/assets/03-generic/images/menu-close.svg';
import Escapable from '@/mixins/escapable';

export default {
  name: 'Flyout',
  mixins: [Clickaway, Escapable],
  props: {
    type: {
      type: String,
      default: null,
    },
    opened: Boolean,
    closable: {
      type: Boolean,
      default: true,
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open', 'close', 'opened', 'closed'],
  data() {
    return {
      displayState: 'closed',
      isOpened: this.opened,
      closeIcon,
    };
  },
  computed: {
    ...mapGetters({ hasGlobalDialog: 'backdrop/isGloballyActive' }),
    isClosed() {
      return !this.isOpened;
    },
    containerClass() {
      return {
        [`flyout--${this.type}`]: !!this.type,
      };
    },
    isDismissable() {
      return this.dismissable && !this.hasGlobalDialog;
    },
  },
  watch: {
    opened(opened, wasOpened) {
      if (opened === wasOpened) return;
      if (opened) {
        this.open();
      } else {
        this.close();
      }
    },
  },
  methods: {
    ...mapMutations({
      showBackdrop: 'backdrop/SHOW',
      hideBackdrop: 'backdrop/HIDE',
    }),
    open() {
      if (this.isOpened) return;
      this.isOpened = true;
      this.displayState = 'opening';
      this.$emit('open');
      this.showBackdrop();
    },
    close() {
      if (this.isClosed) return;
      this.isOpened = false;
      this.displayState = 'closing';
      this.$emit('close');
      this.hideBackdrop();
    },
    onOpened() {
      this.displayState = 'opened';
      this.$emit('opened');
    },
    onClosed() {
      this.displayState = 'closed';
      this.$emit('closed');
    },
    onEsc() {
      this.dismiss();
    },
    dismiss() {
      if (this.isDismissable) this.close();
    },
    focusClose() {
      if (this.isOpened && this.closable) {
        this.$refs.closeButton.focus();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'assets/base';

.flyout {
  position: relative;
  z-index: 8;
  background-color: color(white);
  cursor: auto;
  font-size: 18px;
  line-height: 27px;
  padding: 36px 0;
  text-align: left;
  width: 100%;
  user-select: none;

  &--transition {
    transition: {
      property: transform;
      duration: 200ms;
      timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    }
  }

  &--is-closed {
    transform: translateY(-100%);
  }

  &--is-opened {
    transform: none;
    overflow-y: auto;
    height: 100%;
  }

  &__content {
    display: flex;
    @include mq($mq-medium) {
      justify-content: center;
      padding-right: 0;
    }
  }

  &__close-container {
    position: relative;
    height: 0;
  }

  &__close-button {
    position: absolute;
    right: 0;
    z-index: 5;
  }

  &__icon-close {
    width: 18px;
    height: 18px;
  }

  &--error,
  &--warning,
  &--success {
    align-items: center;
    border-top: {
      style: solid;
      width: 4px;
    }
  }

  &--error,
  &--warning {
    border-top-color: color(yellow, 2000);
  }

  &--success {
    border-top-color: $color-success;
    color: $color-success;
  }
}
</style>
