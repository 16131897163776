import isFunction from 'lodash-es/isFunction';

function initDefault(valueOrFunc) {
  if (isFunction(valueOrFunc)) return valueOrFunc();
  return valueOrFunc;
}

export default (fields) => ({
  props: {
    options: {
      type: Object,
      required: true,
    },
    dialogOptions: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    const data = {};
    Object.keys(fields).forEach((key) => {
      let value;
      if (key in this.options) {
        value = this.options[key];
      } else {
        value = initDefault(fields[key]);
      }
      data[key] = value;
    });
    return data;
  },
  watch: {
    options: {
      handler(options) {
        Object.keys(fields).forEach((key) => {
          let value;
          if (key in options) {
            value = options[key];
          } else {
            value = initDefault(fields[key]);
          }
          this[key] = value;
        });
      },
      deep: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
