<template>
  <div>
    <div class="dialog-content__header">
      <SvgIcon
        class="icon dialog-container__icon dialog-container__icon--success"
        :image="successIcon"
      />
      <strong>{{ item.name }}</strong>
      wurde in den Warenkorb gelegt
    </div>
    <div
      v-if="additionalNote"
      class="dialog-content__add-to-cart-message"
    >
      <span v-html="additionalNote" />
    </div>

    <div class="dialog-content__buttons grid grid--center">
      <LinkButton
        ref="showCartButton"
        tag="nuxt-link"
        to="/shop/warenkorb"
        transparent
      >
        Warenkorb ansehen
      </LinkButton>

      <LinkButton
        primary
        @click="goToPersonalDataPage()"
      >
        Weiter zur Dateneingabe
      </LinkButton>
    </div>

    <div class="dialog-content__buttons grid grid--center">
      <div>
        <br />
        <LinkButton
          text
          @click="close"
        >
          Weiter einkaufen
        </LinkButton>
      </div>
    </div>

    <template v-if="hasRecommendations">
      <h3 class="text--h3 text--default-color">Vielleicht auch interessant…</h3>
      <OfferTiles
        :offers="truncatedRecommendedOffers"
        class="dialog-container__recommendations"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogContent from '@/mixins/dialog-content';
import successIcon from '@/assets/03-generic/images/check.svg';
import { LinkButton } from '@i22-td-smarthome/component-library';
import OfferTiles from '@/components/offer-tiles.vue';

export default {
  name: 'AddToCartDialogContent',
  mixins: [
    DialogContent({
      item: null,
      recommendedOffers: [],
    }),
  ],
  components: {
    OfferTiles,
    LinkButton,
  },
  data() {
    return {
      successIcon,
      additionalNote: undefined,
    };
  },
  async mounted() {
    await this.$nextTick();
    this.$refs.showCartButton.$el.focus();
    await this.getAdditionalNote();

    await this.fetchOfferRecommendations();
  },
  computed: {
    ...mapGetters('cart', ['checkoutInitUrlSuffix']),
    articleNumber() {
      return this.item.articleNumber;
    },
    hasRecommendations() {
      return this.recommendedOffers.length > 0;
    },
    truncatedRecommendedOffers() {
      return this.recommendedOffers.slice(0, 4);
    },
  },
  methods: {
    async fetchOfferRecommendations() {
      if (!this.articleNumber) return;
      try {
        const recommendations = await $fetch('/api/goliath/offer-recommendations', {
          method: 'POST',
          body: {
            articleNumber: this.articleNumber,
          },
        });

        this.recommendedOffers = recommendations || [];
      } catch (error) {
        console.error(error);
      }
    },
    goToPersonalDataPage() {
      this.close();
      navigateTo(`/shop/${this.checkoutInitUrlSuffix}`);
    },
    async getAdditionalNote() {
      try {
        const content = await $fetch(
          `/content-repo/content-json/hooks/item-added-to-cart/${this.item.articleNumber}`
        ).catch(() => {});
        if (!content || !content?.additionalNote) return null;
        this.additionalNote = content.additionalNote.join('');
      } catch (e) {}
    },
  },
};
</script>
