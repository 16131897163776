<template>
  <client-only>
    <StatusToolbar
      :show-toolbar="showToolbar"
      json-url="/appmonitoring/status.json"
      :node-env="nodeEnv"
      :features="featuresClient"
      :current-revision="currentRevision"
    />
  </client-only>
</template>

<script>
import StatusToolbar from '@i22/status-toolbar';
import '@i22/status-toolbar/dist/style.css';
import currentRevision from '@/current_revision.json';

export default {
  name: 'StatusToolbarWrapper',
  components: { StatusToolbar },
  inject: ['featuresClient'],
  data() {
    return {
      currentRevision: JSON.parse(JSON.stringify(currentRevision || {})),
    };
  },
  computed: {
    nodeEnv() {
      return this.$config.public.mode.name;
    },
    showToolbar() {
      return !this.$config.public.mode.isProduction;
    },
  },
};
</script>
